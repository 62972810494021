var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "carousel",
          expression: "widget.compType === 'carousel'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_width") } },
          [
            _c("el-input-number", {
              attrs: { min: 0, max: 100 },
              model: {
                value: _vm.widget.properties.width,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "width", $$v)
                },
                expression: "widget.properties.width",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_height") } },
          [
            _c("el-input-number", {
              staticClass: "input",
              attrs: { min: 0 },
              model: {
                value: _vm.widget.properties.height,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "height", $$v)
                },
                expression: "widget.properties.height",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_display_label") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.showLabel,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "showLabel", $$v)
                },
                expression: "widget.properties.showLabel",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_upload_address") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: { clearable: "" },
              model: {
                value: _vm.widget.properties.action,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "action", $$v)
                },
                expression: "widget.properties.action",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_Jump_mode") } },
          [
            _c(
              "el-select",
              {
                model: {
                  value: _vm.widget.properties.target,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "target", $$v)
                  },
                  expression: "widget.properties.target",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "新开窗口", value: "_blank" },
                }),
                _c("el-option", {
                  attrs: { label: "替换当前窗口", value: "_self" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_text_color") } },
          [
            _c("el-color-picker", {
              model: {
                value: _vm.widget.properties.fontColor,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "fontColor", $$v)
                },
                expression: "widget.properties.fontColor",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_Paramètres") } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.openSetDataDilog },
              },
              [_vm._v("参数配置")]
            ),
          ],
          1
        ),
      ],
      _c(
        "el-dialog",
        {
          attrs: {
            title: "参数配置",
            visible: _vm.dialogVisible,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              _vm.dialogVisible = false
            },
          },
        },
        [
          _c(
            "el-card",
            { staticStyle: { height: "600px", "overflow-y": "auto" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addData } },
                [_vm._v(_vm._s(_vm.$t("lang_new")))]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "10px" },
                  attrs: {
                    "cell-style": {
                      textAlign: "center",
                    },
                    "header-cell-style": {
                      textAlign: "center",
                    },
                    data: _vm.codeSettingData,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("lang_describe") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input", {
                              model: {
                                value: row.description,
                                callback: function ($$v) {
                                  _vm.$set(row, "description", $$v)
                                },
                                expression: "row.description",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "跳转链接" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input", {
                              model: {
                                value: row.linkUrl,
                                callback: function ($$v) {
                                  _vm.$set(row, "linkUrl", $$v)
                                },
                                expression: "row.linkUrl",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("picture") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("glb-img-upload", {
                              attrs: {
                                width: "80px",
                                height: "80px",
                                "upload-url": _vm.uploadUrl,
                                "pub-preview-url": _vm.pubPreviewUrl,
                                maxUploadSize: 1,
                              },
                              model: {
                                value: row.imageId,
                                callback: function ($$v) {
                                  _vm.$set(row, "imageId", $$v)
                                },
                                expression: "row.imageId",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "排序" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              model: {
                                value: row.sort,
                                callback: function ($$v) {
                                  _vm.$set(row, "sort", $$v)
                                },
                                expression: "row.sort",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { width: "80", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteData($index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v(_vm._s(_vm.$t("lang_save_")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
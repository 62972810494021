































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
/**
 * input的配置项
 */
@Component({
  name: 'TextConfig',
  components: {}
})
export default class extends Mixins(ElePropertiesMixins) {
  dialogVisible: Boolean = false;
  codeSettingData: any = [];
  // 图片组件上传地址
  uploadUrl: string = '/river-fs/file/create';
  // 图片组件预览地址
  pubPreviewUrl: string = '/river-fs/file/view';

  mounted() {
    const properties: any = this.widget.properties;
    if (properties.data && properties.data.length) {
      this.codeSettingData = properties.data;
    } else {
      this.codeSettingData.push({
        imageId: '',
        description: '',
        linkUrl: '',
        id: SystemUtil.uuid()
      });
    }
  }

  openSetDataDilog() {
    this.dialogVisible = true;
  }
  addData() {
    this.codeSettingData.push({
      imageId: '',
      description: '',
      linkUrl: '',
      id: SystemUtil.uuid()
    });
  }
  deleteData(index) {
    this.codeSettingData.splice(index, 1);
  }
  save() {
    (this.widget.properties as any).data = this.codeSettingData;
    this.dialogVisible = false;
  }
}
